<template>
    <div class="infomation-detail">
        <div class="router-info">
            <p class="common_min_width">您的位置： <span @click="onGoRoute('home')">首页</span> > <span @click="onGoRoute('infomation')">新闻资讯</span> ><span @click="onGoList()" v-if="detail.category_name">{{detail.category_name}}</span> >{{detail.title}}</p> 
        </div>
        <div class="content">
            <div>
                <div class="floatLf detail_box">
                    <h1>{{detail.title}}</h1>
                    <p><span v-if="detail.source">{{detail.source}} | </span>{{detail.time|set_time}}</p>
                    <div class="info html-content"  v-html="detail.content"></div>
                </div>
                <div class="floatLf paddingLeft" ></div>
                <div class="floatLf recommended_news_box" :style="{'opacity':recommendedList.length>0?1:0}">
                    <div class="title_box">推荐新闻</div>
                    <template v-for="item in recommendedList">
                        <div class="tap_" :key="item.id" @click="goDetail(item.id)">
                            <p>{{item.title}}</p>
                            <div><span v-if="item.source">{{item.source}} | </span><span>{{detail.time|set_time}}</span></div>
                        </div>
                    </template>
                </div>
                <div class="floatLf you_like_box" v-if="similarList.length>0">
                    <div class="line"></div>
                    <div class="news_list">
                        <div v-for="item in similarList" :key="item.id">
                            <div class="tap_" @click="goDetail(item.id)" >
                                <div class="floatLf back_img img_tap" :style="'background-image: url('+item.thumbnail+')'"></div>
                                <div class="floatLf paddingL"></div>
                                <div class="floatLf detail_tap">
                                    <h1 class="ellipsis">{{item.title}}</h1>
                                    <p>{{item.excerpt || item.content|edit_content}}</p>
                                </div>
                               <div class="floatLf source_tap"><span v-if="item.source">{{item.source}} | </span>{{detail.time|set_time}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footerComponent ></footerComponent>
    </div>
</template>
<script>
export default {
    name:'infomationDetail',
    data(){
        return{
            recommendedList:[],
            similarList:[],
            detail:{},
            type:'',
            prefixImg:''
        }
    },
    created(){
        this.initData()
    },
    watch:{
        $route:'initData'
    },
    methods:{
        onGoRoute(name){
            this.$router.push({name: name})
        },
        onGoList(){
            this.$router.push({name: 'infomationList', query:{name: this.detail.category_name, id:this.detail.category_id}})
        },
        goDetail(id){
            this.$router.push({name:'infomationDetail', query:{id: id}})
        },
        initData(){
            let query = this.$route.query||{};
            this.type = query.type;
            this.$Request({
                url: `/portal/read/${query.id}`,
                method: 'get',
            }).then(res=>{
                if(res.code==1){
                    let data = res.data||{};
                    this.prefixImg = data.url+'/';

                    this.similarList = (data.similar||[]).map(item=>{
                        if(this.type == 'expert_think'||data.category_name=='专家智库'){
                            item.thumbnail = this.prefixImg+(item.img&&item.img[0]&&item.img[0].url)
                        }
                        return item
                    });
                    this.recommendedList = data.recommended||[];
                    delete data.similar;
                    delete data.recommended;
                    this.detail = data;
                }else{
                    this.$message.error(res.msg||'获取数据失败')
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.infomation-detail{
    padding-top: 70px;

    .router-info{
        background-color: #EEEFF5;
        p{
            width: 1200px;
            margin: 0 auto;
            font-size: 14px;
            color: #999999;
            padding: 10px 0;
            &>span{
                cursor: pointer;
                &:hover{
                    color: $lv;
                }
            }
        }
    }
    .content{
        padding: 50px 15px 0;
        width: 1200px;
        margin: 0 auto;
        &>div{
            display: inline-block;
            width: 100%;
        }
        .detail_box,.you_like_box{
            width: 67%;
        }
        .paddingLeft{
            width: 4%;height: 1px;
        }
        .recommended_news_box{
            width: 29%;
            background: #FFFFFF;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
            border-top: 8px solid $lv;
            padding: 0 20px;
            color: #333;
            .title_box{
                margin-top: 20px;
                margin-bottom: 15px;
                font-size: 14px;
                border-bottom: 2px solid $lv;
                padding-bottom: 5px;
                display: inline-block;
            }
            .tap_{
                padding: 16px 0;
                &:not(:last-child){
                    border-bottom: 1px solid #e6e6e6;
                }
                p{
                    font-size: 16px;
                    margin-bottom: 16px;
                }
                span{
                    color: #999999;
                }
                &:hover{
                    cursor: pointer;
                    p{
                        color: $lv;
                    } 
                }
            }
        }
        .detail_box{
            color: #333333;
            font-size: 14px;
            padding-bottom: 40px!important;
            h1{
                font-size: 30px;
                font-weight: 400;
            }
            p{
                padding: 10px 0;
                padding-bottom: 40px;
            }
            .info{
                line-height: 21px;
                min-height: 500px;
            }
        }
        .you_like_box{
            padding-bottom: 40px;
            .line{
                width: 100%;
                height: 2px;
                background-color: $lv;
            }
            .news_list{
                margin-top: 15px;
                &>div{
                    padding: 10px;
                    cursor: pointer;
                    &:hover{
                        box-shadow: 0 2px 11px 10px rgba(0,0,0,.1);
                    }
                }
                .tap_{
                    font-size: 14px;
                    color: #333;
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    min-height: 130px;
                    .img_tap{
                        width: 284px;
                        height: 163px;
                        background-image: url('../assets/img/home_6_1.png');
                        background-color: #ccc;
                    }
                    .paddingL{
                        width: 4%;
                        height: 1px;
                    }
                    .detail_tap{
                        width: 55%;
                        h1{
                            font-size: 18px;
                            font-weight: 400;
                        }
                        p{
                            margin-top: 15px;
                            @include textOver(4);
                        }
                    }
                    .source_tap{
                        position: absolute;
                        bottom: 0;
                        left: 41%;
                        color: #999999;
                        bottom: 0;
                    }
                }
            }
        }
    }
}
</style>